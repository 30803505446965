<template>
  <v-container>
    <v-row justify="center">
      ログインしています...
    </v-row>
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
  </v-container>

</template>

<script>
import store from "@/store"
import axios from "axios";


export default {
  name: 'SignInProcessing',

  data: () => ({
    }
  ),
  created() {
    const query = this.$route.query
    var request_code = query.code
    var url = process.env.VUE_APP_API_ORIGIN + "/api/line_login/get_access_token?code=" + request_code
    axios({
      method: "GET",
      url: url,
      withCredentials: true,
      timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
    })
    .then(res => {
      store.commit("onSignedInWithLine", res.data)
      var lineUserId = store.state.auth.lineUser.sub
      var token = store.state.auth.lineAccessToken
      var url = process.env.VUE_APP_API_ORIGIN + "/api/account/get?account_id=" + lineUserId
      const headers = {
        'content-type': 'application/json',
        'x-line-access-token': token
      }
      axios({
        method: "GET",
        url: url,
        headers: headers,
        withCredentials: true,
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        store.commit("onHandleNameUpdate", res.data.handle_name)
        this.$router.push("/home")
      })
    })
  }
}
</script>